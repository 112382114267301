/* eslint-disable no-console */
import axios from 'axios';
import * as BandwidthUA from '@bandwidth/bw-webrtc-sdk';
import { CALL_STATUS_ACTIVE } from '../constants/RhinocallConstants';
import store from '../store';
import { updateCallOrCancel, handleCompleteCall, setDisplay } from '../reducers/rhinocallReducer';

const activePhoneSession = {
  phone: null,
  call: null,
  sessionId: null,
  callRecord: null,
};
const extraHeaders = ['User-to-User:eyJhbGciOiJIUzI1NiJ9.WyJoaSJd.-znkjYyCkgz4djmHUPSXl9YrJ6Nix_XvmlwKGFh5ERM;encoding=jwt;aGVsbG8gd29ybGQ;encoding=base64'];
const audioStreamChangedSubscribers = new Map();
const callStateChangedSubscribers = new Map();

export async function startCall(payload) {
  const callLog = await createSession(payload);
  const { sessionId } = activePhoneSession;
  return { sessionId, callLog };
}

export async function endCall() {
  closeActiveSession();
}

export function muteAudio(value) {
  if (activePhoneSession && activePhoneSession.call) {
    activePhoneSession.call.muteAudio(value);
  }
}

export function isMuted() {
  if (activePhoneSession && activePhoneSession.call) {
    return activePhoneSession.call.isMuted();
  }
  return false;
}

export function sendDTMF(value) {
  if (activePhoneSession && activePhoneSession.call) {
    activePhoneSession.call.sendDTMF(value);
  }
}

export function registerAudioStreamChanged(consumerId, callback) {
  if (consumerId && callback) {
    if (!audioStreamChangedSubscribers.has(consumerId)) {
      audioStreamChangedSubscribers.set(consumerId, callback);
    }
  }
}

export function unregisterAudioStreamChanged(consumerId) {
  if (audioStreamChangedSubscribers.has(consumerId)) {
    audioStreamChangedSubscribers.delete(consumerId);
  }
}

function notifyAudioStreamChangedSubscribers(remoteStream) {
  audioStreamChangedSubscribers.forEach((callback) => callback(remoteStream));
}

export function registerCallStateChangedSubscribers(consumerId, callback) {
  if (consumerId && callback && !callStateChangedSubscribers.has(consumerId)) {
    callStateChangedSubscribers.set(consumerId, callback);
  }
}

export function unregisterCallStateChangedSubscribers(consumerId) {
  if (consumerId && callStateChangedSubscribers.has(consumerId)) {
    callStateChangedSubscribers.delete(consumerId);
  }
}

// function notifyCallStateChangedSubscribers(callState) {
//   callStateChangedSubscribers.forEach((callback) => callback(callState));
// }

async function createSession(payload) {
  closeActiveSession();
  const phone = new BandwidthUA.BandwidthUA();
  const connectionInfo = await getConnectionInfo();
  const { serverConfig, auth } = connectionInfo.data;
  phone.setWebSocketKeepAlive(5, false, false, 5, true);
  phone.setServerConfig(serverConfig.addresses,
    serverConfig.domain,
    serverConfig.iceServers);
  phone.setOAuthToken(auth.access_token);
  await phone.checkAvailableDevices();
  phone.setListeners({
    loginStateChanged,
    outgoingCallProgress,
    callTerminated,
    callConfirmed,
    callShowStreams,
    callHoldStateChanged,
    incomingCall,
  });
  phone.setAccount(`${payload.fromNumber}`, 'Rhinogram', '');
  await phone.init();
  const callLog = await createCall(payload);
  activePhoneSession.phone = phone;
  activePhoneSession.call = phone.call(`${payload.toNumber}`, extraHeaders);
  activePhoneSession.sessionId = callLog.data._id;
  activePhoneSession.callRecord = callLog?.data;
  return callLog;
}

async function closeActiveSession() {
  const { phone, call } = activePhoneSession;
  if (call) {
    call.terminate();
  }
  if (phone && phone.isInitialized()) {
    phone.deinit();
  }
  activePhoneSession.phone = null;
  activePhoneSession.call = null;
  activePhoneSession.sessionId = null;
  activePhoneSession.callRecord = null;
}

async function callShowStreams(call, localStream, remoteStream) {
  // The provided function is invoked when media streams have been established. This often corresponds with the answering of the call.
  // console.log('WOOT > callShowStreams', remoteStream);
  notifyAudioStreamChangedSubscribers(remoteStream);
  const callLog = await updateCall(activePhoneSession?.sessionId, { status: CALL_STATUS_ACTIVE });
  store.dispatch(updateCallOrCancel(callLog?.data));
}

function incomingCall(call, invite) {
  // NOT CURRENTLY USED
  console.log('WOOT > incomingCall', call, invite);
}

function loginStateChanged(isLogin, cause) {
  // NOT CURRENTLY USED
  // The provided function is invoked when the connection state between the Bandwidth WebRTC server and the Client changes.
  // This can occur when a requested connection is established in a bi-directional manner, or when that connection fails.
  // There are other causes of this event that will be documented elsewhere.
  console.log('WOOT > loginStateChanged', isLogin, cause);
  // possible causes are connected, disconnected, login failed, login, logout
}

function outgoingCallProgress(call, response) {
  // The provided function is invoked when there is a change in the state of the call from the client.
  // A classic example of this is when a ringing state is detected.
  console.log('WOOT > outgoingCallProgress', call, response);
  // notifyCallStateChangedSubscribers(CALL_STATUS_INITIATED);
}

async function callTerminated(call, message, cause) {
  // await updateCall(activePhoneSession?.sessionId, { status: CALL_STATUS_COMPLETED, disconnectCause: cause });
  store.dispatch(setDisplay('complete'));
  store.dispatch(handleCompleteCall({ _id: activePhoneSession?.sessionId, disconnectCause: cause }));
}

async function callConfirmed(call, message) {
  const callLog = await updateCall(activePhoneSession?.sessionId, { callId: message?.call_id });
  store.dispatch(updateCallOrCancel(callLog?.data));
}

function callHoldStateChanged(call, isHold, isRemote) {
  // NOT CURRENTLY USED
  // If the system is configured to support hold state conditions, this function will be invoked on changes in the hold state of the call.
  console.log('WOOT > callHoldStateChanged', call, isHold, isRemote);
}

function getConnectionInfo() {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls/connectionInfo`);
}

function createCall(data) {
  return axios.post(`${process.env.REACT_APP_CALL_BASE_URL}/calls`, data);
}

export function updateCall(callId, data) {
  return axios.patch(`${process.env.REACT_APP_CALL_BASE_URL}/calls/${callId}`, data);
}

// legacy
export function postCall(data) {
  return axios.post(`${process.env.REACT_APP_CALL_BASE_URL}/calls`, data);
}

export function expireCall() {
  return axios.patch(`${process.env.REACT_APP_CALL_BASE_URL}/calls/expired`);
}

export function patchCall(callId, data) {
  return axios.patch(`${process.env.REACT_APP_CALL_BASE_URL}/calls/${callId}`, data);
}

export function getOrganization(organizationId) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/org/${organizationId}`);
}

export function getOrganizationToken(organizationId) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/org/${organizationId}/token`);
}

export function getCallsByStatus(filter) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls?status${filter}`);
}

export function postOrganization(payload) {
  return axios.post(`${process.env.REACT_APP_CALL_BASE_URL}/org/${payload.organizationId}`, payload);
}

export function getLastCallByUser() {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls?latest=true`);
}

export function getCall(callLogId) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls/${callLogId}`);
}
